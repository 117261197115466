import React from 'react';
import 'boxicons/css/boxicons.min.css'; 
import Logo from '../assets/img/newleaf_logo-2.0.png'; 

const Footer = () => {
  return (
    <footer id="footer">
      <div className="container text-center">
        <img src={Logo} alt="The New Leaf Media, LLC Logo" className="footer-logo mb-3" /> 
        <h3>The New Leaf Media, LLC</h3>
        <p>Redefine Success. World-class representation for your world-class talent!</p>
        <div className="social-links">
          <a href="https://www.facebook.com" className="facebook" target="_blank" rel="noopener noreferrer">
            <i className="bx bxl-facebook"></i>
          </a>
          <a href="https://www.instagram.com/newleafmediallc/" className="instagram" target="_blank" rel="noopener noreferrer">
            <i className="bx bxl-instagram"></i>
          </a>
          <a href="https://www.linkedin.com/company/newleafmediallc" className="linkedin" target="_blank" rel="noopener noreferrer">
            <i className="bx bxl-linkedin"></i>
          </a>
        </div>
        <div className="copyright">
          &copy; Copyright <strong><span>The New Leaf Media, LLC</span></strong>. All Rights Reserved
        </div>
        <div className="credits">
          <a href="/privacy-policy">Privacy Policy</a>
          <a href="/fulfillment-policy">Fulfillment Policy</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
