import React, { useState, Fragment } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import AppNavBar from './components/AppNavBar';
import Footer from './components/Footer';

import Home from './pages/Home';
import Contact from './pages/Contact';
import PrivacyPolicy from './pages/Privacy';
import FulfillmentPolicy from './pages/Fulfillment';
import NotFound from './pages/NotFound';
import Modal from './components/Modal'; 

import './assets/css/App.css';

function App() {
  const [showModal, setShowModal] = useState(true); 
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const location = useLocation();

  const validRoutes = ['/', '/contact', '/privacy-policy', '/fulfillment-policy'];

  const isNotFoundPage = !validRoutes.includes(location.pathname);

  const isHomePage = location.pathname === '/';

  return (
    <Fragment>
      {isHomePage && <Modal show={showModal} onClose={handleCloseModal} />}

      {!isNotFoundPage && <AppNavBar />}

      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/contact" component={Contact} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/fulfillment-policy" component={FulfillmentPolicy}/>
        <Route component={NotFound} />
      </Switch>

      {!isNotFoundPage && <Footer />}
    </Fragment>
  );
}

export default App;
