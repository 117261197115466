import React, { useEffect } from 'react';
import SafeComponent from '../components/security/SafeComponent';

import img01 from '../assets/img/policy.png'; 

const FulfillmentPolicy = () => {
     useEffect(() => {
        const sectionId = window.location.hash.slice(1);
        if (sectionId) {
          handleScrollToSection(sectionId);
        }
      }, []);

    const handleScrollToSection = (sectionId) => {
        const section = document.querySelector(`#${sectionId}`);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
      };

    const complaintsContent = `
    <h5 className="fw-bold">10. Handling Customer Complaints and Suggestions</h5>
    <p align="justify">
        You may direct any questions or inquiries with respect to our privacy policy or our practices by contacting
        <span style="color: rgb(54, 149, 144);"> 1-888-415-4882</span> by phone or by email at
        <span style="color: rgb(54, 149, 144);"> info@thenewleafmedia.com</span>.
    </p>
    `;
      return (
        <>
            <section id="hero2" className="d-flex flex-column justify-content-end align-items-center">
            <svg
            className="hero-waves"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28"
            preserveAspectRatio="none"
            >
            <defs>
                <path
                id="wave-path"
                d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
                ></path>
            </defs>
            <g className="wave1">
                <use xlinkHref="#wave-path" x="50" y="3" fill="rgba(255,255,255, .1)"></use>
            </g>
            <g className="wave2">
                <use xlinkHref="#wave-path" x="50" y="0" fill="rgba(255,255,255, .2)"></use>
            </g>
            <g className="wave3">
                <use xlinkHref="#wave-path" x="50" y="9" fill="#fff"></use>
            </g>
            </svg>
        </section>
        <main id="privacy-policy">
        <section id="privacy-policy" className="policy">
          <div className="container">
            <div className="section-title mb-3">
              <h2>NewLeaf</h2>
              <p>Fulfillment Policy</p>
            </div>
            <div className="row content">
              <div className="col-lg-6">
                {/* <p align="justify">
                  New Leaf Media is committed to maintaining the accuracy, confidentiality, and security of your
                  personally identifiable information ("Personal Information"). As part of this commitment, our privacy
                  policy governs our actions as they relate to the collection, use, and disclosure of Personal Information.
                  Our privacy policy is based upon the values set by the United States Privacy Law and is governed by,
                  construed, and enforced in accordance with the laws of the State of Ohio and the United States of America
                  applicable to Ohio-based businesses and websites. Your use of this Website constitutes your consent to
                  their jurisdiction.
                </p> */}

                <h5 className="fw-bold">New Leaf Media Fulfillment Policy for Payments via Stripe</h5>
                <p align="justify">
                These guidelines will assist users in understanding how New Leaf Media LLC processes orders and payments facilitated through the payment processor Stripe.
                </p>

                <p>
                This document outlines the terminology used in the Terms and Conditions, Privacy Statement, and Disclaimer Notice, as well as any associated Agreements. "Client", 
                "You", and "Your" refer to the individual using this website and adhering to the Company's terms and conditions, 
                whether outlined here or in a separate written contract. "The Company", "Ourselves", "We", "Our", and "Us" denote our organization. 
                "Party", "Parties", or "Us" encompasses both the Client and ourselves. All terms relate to the offer, acceptance, and payment considerations necessary
                 to provide our assistance to the Client in the most appropriate manner, with the express purpose of meeting the Client's needs regarding the Company's 
                 stated services, in accordance with and subject to the prevailing laws of the State of Ohio. The terminology or other words used in the singular, plural, capitalization, 
                 and/or he/she or they forms are considered interchangeable and refer to the same.
                </p>

                <h5 className="fw-bold">Fulfillment Policy</h5>
                <p align="justify">
                At New Leaf Media LLC, we strive to provide satisfactory services. Customer satisfaction is our top priority, and we take all complaints 
                about our services very seriously. This Fulfillment Policy outlines our delivery policy and the rules and guidelines for refunds on services 
                purchased from our Company. Clients are advised to carefully review this policy to fully 
                comprehend their rights and the stipulations governing the refund process. Our services are invoiced in U.S. Dollars.
                </p>

                <h5 className="fw-bold">Delivery Policy</h5>
                <p align="justify">
                At New Leaf Media, we provide digital marketing and publication solutions to our clients. The company delivers its services through electronic and telephone-based 
                communication channels, tailored to meet the specific needs and preferences of its clientele.
                </p>

                <h5 className="fw-bold">Refunds</h5>
                <p align="justify" >
                We may offer refunds under the following conditions:
                </p>
                <ul className='refund-bullet'>
                    {/* <li><span>If the service was not carried out at all during the period covered by our agreement.</span></li>
                    <li><span>If the work does not comply with content guidelines, a refund will be issued minus a processing fee of $75.</span></li> */}
                    <li><span>&#8226;</span> If the service was not carried out at all during the period covered by our agreement.</li>
                    <li><span>&#8226;</span> If the work does not comply with content guidelines, a refund will be issued minus a processing fee of $75.</li>
                </ul>

                <h5 className="fw-bold">Exceptions</h5>
                <p align="justify">
                    Notwithstanding other provisions of this Policy, we may decline to provide returns or refunds under the following circumstances:
                </p>

                <ul className='refund-bullet'>
                    {/* <li><span>If the service was not carried out at all during the period covered by our agreement.</span></li>
                    <li><span>If the work does not comply with content guidelines, a refund will be issued minus a processing fee of $75.</span></li> */}
                    <li><span>&#8226;</span> You received the requested service but later decided it was not what you wanted.</li>
                    <li><span>&#8226;</span> You were displeased with the acquired goods or ascertained they served no purpose for you.</li>
                    <li><span>&#8226;</span> You were informed of or knew about the issues with the service prior to completing the purchase.</li>
                    <li><span>&#8226;</span> You received the service or product and neglected to submit a report within 7 days of the date of receipt.</li>
                </ul>

                <h5 className="fw-bold">Termination Policy</h5>
                <p align="justify">
                Once the contract is signed with New Leaf Media LLC, cancellation is no longer possible after the grace period has elapsed. 
                The termination of the agreement and any refunds will be based on the services rendered and the fulfillment status.
                </p>
              </div>

              <div className="col-lg-6 pt-4 pt-lg-0">
                <h5 className="fw-bold">Discontinuation of Project Initiatives</h5>
                <p align="justify">
                If the Client fails to engage or remains unresponsive to New Leaf Media's efforts to initiate, continue, or complete the delivery of the Services over thirty days, the project may be abandoned at New Leaf Media's discretion. 
                In such an event, New Leaf Media reserves the right to withhold any refund for the Services, and the Client acknowledges the forfeiture of any amounts previously paid for the Services, 
                as outlined in the original Work Order, as applicable.
                </p>

                <h5 className="fw-bold">Administrative Fee</h5>
                <p align="justify">
                All refunds are subject to 20% administrative fee.
                </p>

                <h5 className="fw-bold">Submitting a complaint</h5>
                <p align="justify">
                If you are dissatisfied with the service we have provided and are eligible for a refund under this Policy, please send an email to info@thenewleafmedia.com detailing your complaints. 
                We will promptly verify your concerns. Refunds will be subjected to further investigation and due process.
                </p>
                
                {/* <p>
                  <SafeComponent userInput={complaintsContent} />
                </p> */}

                <h5 className="fw-bold">Contact Us</h5>
                <p align="justify">
                For inquiries and concerns, you may contact us through the following channels:
                </p>
                <p className="fw-bold m-0">Email</p>
                <div>
                    <p>Subject: Complaint</p>
                    <p className='m-0'>Email address:</p>
                    <div className='fulfill-email-addresses'>
                        <p>info@thenewleafmedia.com</p>
                        <p>finance@thenewleafmedia.com</p>
                    </div>
                </div>

                <p className="fw-bold m-0">Phone:</p>
                <div>
                    {/* <p className='m-0'>Email address:</p> */}
                    <div className='fulfill-phone'>
                        <p>Phone: <span className='text-color-contact'>1-888-415-4882 ext. 101</span></p>
                        <p>Fax Number: <span className='text-color-contact'>(614) 405- 9230</span></p>
                    </div>
                </div>

                <p className="fw-bold m-0">Mail:</p>
                <div>
                    {/* <p className='m-0'>Email address:</p> */}
                    <div className='fulfill-phone'>
                        <p><span className='text-color-contact'>470 W Broad St #1276</span></p>
                        <p><span className='text-color-contact'>Columbus, OH 43215</span></p>
                    </div>
                </div>
                
                <div className="d-flex justify-content-center align-items-center">
                  <img src={img01} alt="Privacy Policy" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
        </>
      )      

}

export default FulfillmentPolicy;