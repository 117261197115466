import React from "react";

import adVideo from '../assets/img/video/final.mp4'; 

const Modal = ({ show, onClose }) => {
  if (!show) return null;

  return (
    <div className="advertisement">
      <div className="modal-overlay" onClick={onClose}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <button className="modal-close-button" onClick={onClose}>
            &times;
          </button>

          {/* Video Element */}
          <video
            src={adVideo}
            className="modal-gif"
            autoPlay
            loop
            muted
            controls
          />
        </div>
      </div>
    </div>
  );
};

export default Modal;
